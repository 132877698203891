@value layout: "../../../../../styles/variables/layout.module.css";
@value breakpoint-l, breakpoint-xl, gap-xs, gap-s, gap-m, gap-l from layout;
@value colors: "../../../../../styles/variables/colors.module.css";
@value color-blue400, color-red400 from colors;
@value fonts: "../../../../../styles/variables/fonts.module.css";
@value font-weight-bold, font-size-m from fonts;

.list {
    all: revert;
    list-style: none;
    margin: 0;

    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    gap: gap-m;
    padding: 0;
    align-items: center;
}

@media (width <= breakpoint-xl) {
    .list {
        justify-content: flex-start;
    }
}

@media (width <= breakpoint-l) {
    .list {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: calc(gap-m + gap-xs);
    }
}

.list > li {
    all: revert;
    margin: 0;
    padding: 0;

    display: flex;
}

.wrapper {
    display: flex;
    gap: gap-s;
    text-transform: uppercase;
    font-size: font-size-m;
    font-weight: font-weight-bold;
    align-items: center;
    line-height: font-size-m;
    color: color-blue400;
    fill: color-blue400;
}

@media (prefers-reduced-motion: no-preference) {
    .wrapper {
        transition: color 200ms ease-in-out, fill 200ms ease-in-out;
    }
}

.wrapper:hover {
    color: color-red400;
    fill: color-red400;
}
