@value colors: "../../../../styles/variables/colors.module.css";
@value color-grey500, color-blue300, color-blue400, color-red400 from colors;
@value layout: "../../../../styles/variables/layout.module.css";
@value gap-l, gap-s from layout;
@value fonts: "../../../../styles/variables/fonts.module.css";
@value font-weight-semibold, font-weight-bold, font-size-m, font-size-xs, font-size-xxs from fonts;

.wrapper {
    display: flex;
    flex-direction: column;
    gap: gap-l;
    color: color-grey500;
}

.link-wrapper {
    font-weight: font-weight-semibold;
    color: color-blue400;
}

.cancel-action {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: color-grey500;
  fill: color-grey500;
  background-color: color-white;
  padding: calc(gap-s - 2px) calc(gap-l - 2px);
  font-size: font-size-s;
  font-weight: font-weight-semibold;
  border: 2px solid color-grey500;
}

@media (prefers-reduced-motion: no-preference) {
    .cancel-action {
        transition: color 200ms ease-in-out, fill 200ms ease-in-out, border-color 200ms ease-in-out;
    }
}

.cancel-action:hover {
  color: color-blue400;
  fill: color-blue400;
  border-color: color-blue400;
}

@media (prefers-reduced-motion: no-preference) {
    .link-wrapper {
        transition: color 200ms ease-in-out;
    }
}

.link-wrapper:hover {
    color: color-red400;
}

.success-title {
    all: revert;
    margin: 0;

    color: color-blue400;
    font-size: font-size-m;
}

.success {
    all: revert;
    margin: 0;

    color: color-blue300;
    font-size: font-size-xs;
}

.mandatory-text {
    color: color-red400;
    font-size: font-size-xxs;
    margin: 0;
}
