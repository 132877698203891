@value colors: "../../../styles/variables/colors.module.css";
@value color-blue300 from colors;

.button {
    all: revert;
    padding: 0;
    font: inherit;
    line-height: normal;
    border: none;
    background: none;
    cursor: pointer;

    min-height: 24px;
    min-width: 24px;
    display: inline-flex;
    color: inherit;
    fill: inherit;
    text-transform: inherit;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}
