@import '../../sass/variables';

.toastr {
  position: fixed;
  top: 0;
  left: 0;

  // stylelint-disable-next-line plugin/z-index-value-constraint
  z-index: 1000;
  width: 100%;
  padding: 2px;

  .toast {
    display: flex;
    box-sizing: border-box;
    opacity: 0;
    animation-name: bounce-in;
    animation-duration: 450ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;

    &.closing {
      animation-name: fade-out;
      animation-duration: 450ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }

    &:not(.closing) + .toast {
      margin-top: 5px;
    }

    .message {
      flex: 1;
    }

    .close-button {
      fill: $color-white;
    }
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0.3) translate3d(0,0,0);
      opacity: 0;
    }

    50% {
      transform: scale(1.1);
      opacity: 0.9;
    }

    80% {
      transform: scale(0.89);
      opacity: 1;
    }

    100% {
      transform: scale(1) translate3d(0,0,0);
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      max-height: 45px;
      padding: 8px;
      transform: scale(1);
      opacity: 1;
    }

    100% {
      max-height: 0;
      padding: 0;
      transform: scale(0);
      opacity: 0;
    }
  }
}
