@value layout: "../../../styles/variables/layout.module.css";
@value breakpoint-xl, gap-m, gap-l from layout;
@value colors: "../../../styles/variables/colors.module.css";
@value color-grey300, color-grey500, color-red400, color-white from colors;

.list {
    all: revert;
    list-style: none;
    margin: 0;

    display: flex;
    flex: 1 1 auto;
    gap: gap-m;
    padding: 0;
    align-items: center;
}

@media (width <= breakpoint-xl) {
    .list {
        justify-content: flex-start;
    }
}

.list > li {
    all: revert;
    margin: 0;
    padding: 0;

    display: flex;
}

.wrapper {
    fill: color-grey500;
}

@media (prefers-reduced-motion: no-preference) {
    .wrapper {
        transition: fill 200ms ease-in-out;
    }
}

.wrapper:hover {
    fill: color-red400;
}

.list.light .wrapper {
    fill: color-grey300;
}

.list.light .wrapper:hover {
    fill: color-white;
}