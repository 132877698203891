@import '../../sass/variables';

// hide default ui prompt button as it is implemented in the footer
#ot-sdk-btn-floating {
  display: none;
}

#onetrust-consent-sdk {
  .onetrust-pc-dark-filter {
    background: rgba(255, 255, 255, 0.5);
  }

  #onetrust-banner-sdk {
    .onetrust-vendors-list-handler {
      text-decoration: underline;
    }

    button.cookie-setting-link {
      text-decoration: none;
    }
  }
}

#onetrust-pc-sdk {
  #close-pc-btn-handler.ot-close-icon {
    margin-top: 0;
  }
}
