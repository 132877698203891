@value layout: "../../../styles/variables/layout.module.css";
@value gap-xxs from layout;

.button {
  all: revert;
  padding: 0;
  font: inherit;
  line-height: normal;
  border: none;
  background: none;
  cursor: pointer;
  box-sizing: border-box;

  min-height: 24px;
  min-width: 24px;
  display: flex;
  color: inherit;
  fill: inherit;
  text-transform: inherit;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.button:focus-visible {
  padding: gap-xxs;
}

.align-left {
  justify-content: flex-start;
  text-align: left;
}
