@value colors: "../../../../../../styles/variables/colors.module.css";
@value color-red400, color-white from colors;

.wrapper button:focus-visible {
    border: none;
    outline: 3px dashed color-white;
}

.icon {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 25px;
    height: 26px;
}

.line {
    width: 100%;
    height: 2px;
    background-color: color-white;
}

@media (prefers-reduced-motion: no-preference) {
    .line {
        transition:
            background-color 200ms ease-in-out,
            transform 200ms ease-in-out;
    }
}

.wrapper:hover .line,
.wrapper.active .line {
    background-color: color-red400;
}

.wrapper.active .line {
    opacity: 0;
}

.wrapper.active .line:first-child,
.wrapper.active .line:last-child {
    opacity: 1;

}

.wrapper.active .line:first-child {
    margin-top: 10px;
    transform: rotate(135deg);
    will-change: transform;
}

.wrapper.active .line:last-child {
    margin-top: -24px;
    transform: rotate(-135deg);
    will-change: transform;
}
