@value layout: "../../../../../../styles/variables/layout.module.css";
@value breakpoint-s, breakpoint-xl, gap-m from layout;
@value fonts: "../../../../../../styles/variables/fonts.module.css";
@value font-weight-bold, font-size-xxs, font-family from fonts;

@value categories-font: font-weight-bold font-size-xxs font-family;

.list {
    all: revert;
    list-style: none;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    gap: gap-m;
    justify-content: space-around;
    width: 100%;
}

.list > li {
    all: revert;
    margin: 0;
    padding: 0;

    font: categories-font;
}

.list > li.mobile {
    display: none;
}

@media (width <= breakpoint-xl) {
    .list > li {
        display: none;
    }
}

@media (width <= breakpoint-s) {
    .list > li.mobile  {
        display: revert;
    }
}
