@value colors: "../../../styles/variables/colors.module.css";
@value color-blue300, color-blue400, color-red400, color-white from colors;
@value layout: "../../../styles/variables/layout.module.css";
@value gap-s, gap-l from layout;
@value fonts: "../../../styles/variables/fonts.module.css";
@value font-size-s, font-weight-semibold from fonts;

.button {
  all: revert;
  padding: 0;
  font: inherit;
  line-height: normal;
  border: none;
  background: none;
  cursor: pointer;

  min-height: 24px;
  min-width: 24px;
  display: flex;
  color: inherit;
  fill: inherit;
  text-transform: inherit;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: color-white;
  background-color: color-blue400;
  padding: gap-s gap-l;
  font-size: font-size-s;
  font-weight: font-weight-semibold;
}

@media (prefers-reduced-motion: no-preference) {
    .button {
        transition: background-color 200ms ease-in-out;
    }
}

.button:not(:disabled):hover {
  background-color: color-red400;
}
