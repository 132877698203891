@import '../sass/variables';

.app {
  width: 100%;
}

.top-level-container {
  width: 100%;
  min-height: 100vh;
}
